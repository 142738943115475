import React from "react";

// Root Include
const Root = React.lazy(() => import("./pages/Home/indexRoot"));

//Main Index
const Main = React.lazy(() => import("./pages/Home/indexMain"));
const diVisor = React.lazy(() => import("./pages/Home/diVisor"));
const edgemon = React.lazy(() => import("./pages/Home/edgemon"));
const Contact = React.lazy(() => import("./pages/Home/Contact"));

//Special
const PageComingSoon = React.lazy(() =>
  import("./pages/Pages/Special/PageComingSoon")
);
const PageComingSoon2 = React.lazy(() =>
  import("./pages/Pages/Special/PageComingSoon2")
);
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const PageThankYou = React.lazy(() =>
  import("./pages/Pages/Special/PageThankYou")
);
const PageMaintenance = React.lazy(() =>
  import("./pages/Pages/Special/PageMaintenance")
);



const routes = [
  //routes without Layout

  
  //Index Main
  { path: "/index", component: Main },
  { path: "/divisor", component: diVisor },
  { path: "/edgemon", component: edgemon },
  { path: "/contact", component: Contact },


  //Index root

  { path: "/", component: Main, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false },
];

export default routes;
